import type { Group } from '@/types';
import type { TreeListItem } from '@/components/VTreeList/components/VTreeListItem/VTreeListItem.vue';

export const groupsToTreeListItems = (
  groups: Group[],
  visibilityWhitelist: string[] | null = null,
) => {
  const patchGroup = (group: Group): TreeListItem => {
    return {
      name: group.name,
      data: group,
      id: group.id,
      children: group.subgroups.map((subgroup) => patchGroup(subgroup)),
      isVisible: visibilityWhitelist
        ? visibilityWhitelist.includes(group.id)
        : true,
    };
  };

  return groups.map((group) => patchGroup(group));
};
