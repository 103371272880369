<template>
  <VToast />
  <RouterView />
</template>

<style>
  .p-toast-message-enter-active,
  .p-toast-message-leave-active {
    transition: opacity 150ms ease;
  }

  .p-toast-message-enter-from,
  .p-toast-message-leave-to {
    opacity: 0;
  }
</style>
