import { useAuthStore } from '@/stores/auth';
import { cleanQueryParams } from '@/utils/cleanQueryParams';

export const onRequestFulfilled = async (config: any) => {
  const authStore = useAuthStore();

  // Use auth token if available
  if (authStore.access.value) {
    config.headers.Authorization = `Bearer ${authStore.access.value}`;
  }

  // Remove empty strings and undefined from query params
  config.params = cleanQueryParams(config.params);

  return config;
};
