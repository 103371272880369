import * as authApi from '@/api/auth';

type Token = string | undefined;
type CustomWindow = any & {
  breakAccessToken: () => void;
  breakRefreshToken: () => void;
  breakAllTokens: () => void;
};

export const useAuthStore = createGlobalState(() => {
  const state = useStorage<{ access: Token; refresh: Token }>('authState', {
    access: undefined,
    refresh: undefined,
  });

  const access = computed(() => state.value.access);
  const refresh = computed(() => state.value.refresh);

  const setAccessToken = (token: Token) => {
    state.value.access = token;
  };

  const setRefreshToken = (token: Token) => {
    state.value.refresh = token;
  };

  const getToken = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const data = await authApi.getToken({
        username,
        password,
      });
      setAccessToken(data.access);
      setRefreshToken(data.refresh);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const refreshToken = async () => {
    try {
      if (refresh.value === undefined) {
        throw new Error('Refresh token is undefined');
      }
      const data = await authApi.memRefreshToken(refresh.value);
      setAccessToken(data.access);
      setRefreshToken(data.refresh);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const logout = () => {
    refresh.value && authApi.memLogout(refresh.value);
    setAccessToken(undefined);
    setRefreshToken(undefined);
  };

  const requestPasswordReset = async (email: string) => {
    try {
      await authApi.requestPasswordReset(email);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const confirmPasswordReset = async ({
    uid,
    token,
    newPassword,
  }: {
    uid: string;
    token: string;
    newPassword: string;
  }) => {
    try {
      await authApi.confirmPasswordReset({ uid, token, newPassword });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const passwordChange = async (oldPassword: string, newPassword: string) => {
    try {
      await authApi.passwordChange(oldPassword, newPassword);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const isAuthorized = computed(() => access.value !== undefined);

  // For debugging
  (window as CustomWindow).breakAccessToken = () => {
    setAccessToken('foobarbaz');
  };

  (window as CustomWindow).breakRefreshToken = () => {
    setRefreshToken('foobarbaz');
  };

  (window as CustomWindow).breakAllTokens = () => {
    (window as CustomWindow).breakAccessToken();
    (window as CustomWindow).breakRefreshToken();
  };

  return {
    access,
    refresh,
    setAccessToken,
    setRefreshToken,
    getToken,
    refreshToken,
    logout,
    isAuthorized,
    requestPasswordReset,
    confirmPasswordReset,
    passwordChange,
  };
});
