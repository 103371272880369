import axios from 'axios';
import { onRequestFulfilled } from './onRequestFulfilled';
import { onResponseRejected } from './onResponseRejected';

const request = axios.create();

request.interceptors.request.use(onRequestFulfilled, (error) => error);
request.interceptors.response.use((response) => response, onResponseRejected);

export default request;
