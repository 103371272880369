import { useAuthStore } from '@/stores/auth';
import { displayError } from '@/utils/displayError';
import { useUserStore } from '@/stores/user';
import { getLoginWithRedirect } from '@/router';
import { useOrganizationsStore } from '@/stores/organizations';

export const invite = async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const organizationsStore = useOrganizationsStore();

  const token = to.query.token as string;

  let data = undefined;
  try {
    data = await organizationsStore.validateInvite(token);
  } catch (e) {
    return { name: 'root' };
  }

  if (authStore.isAuthorized.value) {
    if (!userStore.user) {
      await userStore.getUser();
    }

    if (data.invitedUserEmail === userStore.user?.email) {
      return {
        name: 'organizations',
        query: { token, orgName: data.organizationToJoin.name },
      };
    } else {
      displayError([
        `Инвайт предназначен для пользователя ${data.invitedUserEmail}`,
      ]);
      return { name: 'root' };
    }
  } else {
    if (data?.userExists) {
      const route = getLoginWithRedirect(to);
      return { ...route, query: { ...route.query, email: to.query.email } };
    } else {
      return {
        name: 'signup',
        query: { invite: token, email: data.invitedUserEmail },
      };
    }
  }
};
