import { defineStore } from 'pinia';
import type { FetchedItems, Group } from '@/types';
import {
  getGroups,
  addGroup,
  deleteGroup,
  updateGroup,
  attachControllers,
  detachControllers,
} from '@/api/groups';
import { groupsToTreeListItems } from '@/utils/groupsToTreeListItems';
import type { TreeListItem } from '@/components/VTreeList/components/VTreeListItem/VTreeListItem.vue';
import { flattenGroups } from '@/utils/flattenGroup';

export const useGroupsStore = defineStore('groupsStore', {
  state: (): {
    groups: FetchedItems<Group>;
  } => ({
    groups: undefined,
  }),
  actions: {
    async getGroups(organizationId: string) {
      try {
        const groups = await getGroups(organizationId);
        this.groups = groups;
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async addGroup({
      name,
      organizationId,
    }: {
      name: string;
      organizationId: string;
    }) {
      try {
        await addGroup({ name, organization: organizationId });
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async deleteGroups({
      selection,
      organizationId,
    }: {
      selection: string[];
      organizationId: string;
    }) {
      try {
        await Promise.all(selection.map((id) => deleteGroup(id)));
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async updateGroup({
      name,
      id,
      organizationId,
    }: {
      name: string;
      id: string;
      organizationId: string;
    }) {
      try {
        await updateGroup({ name, id });
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async moveGroups({
      selection,
      target,
      organizationId,
    }: {
      selection: string[];
      target: string | null;
      organizationId: string;
    }) {
      try {
        // #FIXME Duplicate update (another one in updateGroup
        await Promise.all(
          selection.map((id) => updateGroup({ id, parent: target })),
        );
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async attachControllers({
      groupId,
      controllers,
      organizationId,
    }: {
      groupId: string;
      controllers: string[];
      organizationId: string;
    }) {
      try {
        await attachControllers({ groupId, controllers });
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async detachControllers({
      controllers,
      organizationId,
    }: {
      controllers: string[];
      organizationId: string;
    }) {
      try {
        await detachControllers(controllers);
        await this.getGroups(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    // Used to use array methods on groups
    flatGroups(): Group[] {
      return this.groups ? flattenGroups(this.groups) : [];
    },
    groupsAsTreeListItems(): TreeListItem[] {
      return this.groups ? groupsToTreeListItems(this.groups) : [];
    },
    getGroupById() {
      return (groupId: string) =>
        this.flatGroups.find((group) => group.id === groupId);
    },
    getGroupPathById() {
      return (groupId: string) => {
        const target = this.getGroupById(groupId);

        if (!target) return undefined;

        const getPossibleParents = (currentLevel: number) =>
          this.flatGroups.filter(
            (group) =>
              group.level === currentLevel - 1 && group.subgroups.length > 0,
          );

        const whitelist: string[] = [target.id];

        const findRoot = (group: Group): Group => {
          const possibleParents = getPossibleParents(group.level);
          const parent = possibleParents.find((parent) =>
            parent.subgroups.includes(group),
          );

          if (parent) {
            whitelist.push(parent.id);
            return findRoot(parent);
          }

          return group;
        };

        const root = findRoot(target);

        const reduceTree = (root: Group): Group => {
          return {
            ...root,
            subgroups: root.subgroups
              .filter((group) => whitelist.includes(group.id))
              .map((group) => reduceTree(group)),
          };
        };

        return reduceTree(root);
      };
    },
  },
});
