import type { BackendController, Controller } from '@/types';
import { omit } from 'lodash-es';

export const patchController = (value: BackendController): Controller => {
  const convertCoordinates = () => {
    if (value.latitude && value.longitude) {
      return [Number(value.latitude), Number(value.longitude)] as [
        number,
        number,
      ];
    }
    return null;
  };

  return {
    ...omit(value, ['latitude', 'longitude']),
    coordinates: convertCoordinates(),
  };
};
