import { useAuthStore } from '@/stores/auth';
import { useOrganizationsStore } from '@/stores/organizations';
import { useControllersStore } from '@/stores/controllers';

export const redirectFromRoot = async () => {
  const authStore = useAuthStore();
  const organizationsStore = useOrganizationsStore();
  const controllersStore = useControllersStore();

  const isAuthorized = authStore.isAuthorized.value;

  if (isAuthorized) {
    await organizationsStore.getOrganizations();
    if (organizationsStore.organizations?.length === 1) {
      const organizationId = organizationsStore.organizations[0].id;
      await controllersStore.getControllers({
        pageSize: 2,
        organizationId,
      });
      if (
        controllersStore.controllers &&
        controllersStore.controllers.length === 1
      ) {
        return {
          name: 'controller',
          params: {
            serialNumber: controllersStore.controllers[0].serialNumber,
            organizationId,
          },
        };
      } else {
        return { name: 'controllers', params: { organizationId } };
      }
    } else {
      return { name: 'organizations' };
    }
  } else {
    return { name: 'login' };
  }
};
