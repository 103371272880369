import { useAuthStore } from '@/stores/auth';
import { displayError } from '@/utils/displayError';

export const deauthorize = async () => {
  const authStore = useAuthStore();

  if (authStore.isAuthorized.value) {
    authStore.logout();

    displayError([
      'Пока что нельзя добавить существующий аккаунт к организации по приглашению. Создайте новый аккаунт используя эту форму.',
    ]);
  }
};
