import app from '@/main';
import type { ToastMessageOptions } from 'primevue/toast';

export const DEFAULT_ERROR_MESSAGE = 'Ошибка!';

export const parseError = (error: any = DEFAULT_ERROR_MESSAGE) => {
  const messages: string[] = [];
  const isNotJson = !(
    error.response.headers['content-type'] === 'application/json; charset=utf-8'
  );

  if (isNotJson || typeof error === 'string') {
    messages.push(error);
  } else {
    if (error.response.data.detail) {
      messages.push(error.response.data.detail);
    } else {
      Object.keys(error.response.data).forEach((key) => {
        const field = error.response.data[key];
        const fields = Array.isArray(field) ? field : [field];
        messages.push(...fields);
      });
    }
  }

  return messages;
};

export const showToast = (
  message: string,
  severity: ToastMessageOptions['severity'] = 'error',
) => {
  app.config.globalProperties.$toast.add({
    summary: message.replace(/\.$/, ''),
    severity,
    life: 6000,
  });
};

export const displayError = (messages: string[]) => {
  messages.forEach((message) => showToast(message));
};
