import type { Group } from '@/types';
import request from '@/utils/request';

export const getGroups = async (organizationId: string): Promise<Group[]> => {
  const url = `/api/v1/groups/`;

  return (
    await request.get(url, { params: { organization_id: organizationId } })
  ).data as Group[];
};

export const addGroup = async ({
  name,
  parent,
  organization,
}: {
  name: string;
  parent?: string;
  organization: string;
}): Promise<Group> => {
  const url = `/api/v1/groups/`;

  return (await request.post(url, { name, parent, organization }))
    .data as Group;
};

export const deleteGroup = async (id: string): Promise<void> => {
  const url = `/api/v1/groups/${id}/`;

  await request.delete(url);
};

export const updateGroup = async ({
  name,
  parent,
  id,
}: {
  name?: string;
  parent?: string | null;
  id: string;
}): Promise<Group> => {
  const url = `/api/v1/groups/${id}/`;

  return (await request.patch(url, { name, parent })).data as Group;
};

export const attachControllers = async ({
  groupId,
  controllers,
}: {
  groupId: string;
  controllers: string[];
}): Promise<void> => {
  const url = `/api/v1/groups/${groupId}/attach-controllers/`;

  await request.post(url, { controllers });
};

export const detachControllers = async (
  controllers: string[],
): Promise<void> => {
  const url = `/api/v1/groups-detach-from-controllers/`;

  await request.post(url, { controllers });
};
