import { defineStore } from 'pinia';
import {
  activateController,
  createTunnel,
  deleteController,
  getController,
  getControllers,
  getControllersCount,
  getDiagnostic,
  getMqtt,
  requestDiagnostic,
  updateController,
  validateController,
} from '@/api/controllers';
import type {
  Controller,
  ControllerEditable,
  ControllerWithCoordinates,
  FetchedItem,
  FetchedItems,
  GetControllersParams,
  TunnelProtocol,
} from '@/types';
import { patchController } from '@/utils/patchController';

// Actions in store can set data to state
// If you need to get data without saving it to state use @/api/* methods directly

export const useControllersStore = defineStore('controllersStore', {
  state: (): {
    controllersOnMap?: ControllerWithCoordinates[];
    controllers: FetchedItems<Controller>;
    controller: FetchedItem<Controller>;
    count: number;
    totalCount?: number;
  } => ({
    controllersOnMap: undefined,
    controllers: undefined,
    controller: undefined,
    count: 0,
    totalCount: undefined,
  }),
  actions: {
    async getControllers({
      page,
      ordering,
      groupIds,
      pageSize,
      organizationId,
      description,
      firmwareVersion,
      groupName,
      serialNumber,
    }: GetControllersParams) {
      try {
        const controllers = await getControllers({
          page,
          ordering,
          groupIds,
          pageSize,
          organizationId,
          description,
          firmwareVersion,
          groupName,
          serialNumber,
        });
        this.controllers = controllers.results.map(patchController);
        this.count = controllers.count;
      } catch (error: any) {
        return Promise.reject(error);
      }
    },

    async getAllControllers({
      description,
      firmwareVersion,
      groupName,
      serialNumber,
      groupIds,
      organizationId,
    }: GetControllersParams) {
      const MAX_CONTROLLERS_NUMBER = 5000;
      try {
        const controllers = await getControllers({
          description,
          firmwareVersion,
          groupName,
          serialNumber,
          groupIds,
          pageSize: MAX_CONTROLLERS_NUMBER,
          organizationId,
        });
        return controllers.results;
      } catch (error: any) {
        return Promise.reject(error);
      }
    },

    async getCount(params: Omit<GetControllersParams, 'page'>) {
      try {
        const { count } = await getControllersCount({
          ...params,
        });
        this.count = count;
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    // This action is used to get unfiltered count of controllers
    async getTotalCount(organizationId: string) {
      try {
        const { count } = await getControllersCount({ organizationId });
        this.totalCount = count;
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getMarkers({
      description,
      firmwareVersion,
      groupName,
      serialNumber,
      ordering,
      groupIds,
      organizationId,
    }: Omit<GetControllersParams, 'page' | 'pageSize'>) {
      // #FIXME
      // There are two potential points of failure in future,
      // that i don't want to fix because they are unreachable now
      // 1. If there will be more than 1000 controllers others will be omited
      // 2. Im not sure that leaflet can handle 1000 points without issues

      const pageSize = 1000;

      try {
        const { results } = await getControllers({
          page: 1,
          ordering,
          groupIds,
          pageSize,
          latitudeMax: 90,
          latitudeMin: -90,
          longitudeMax: 180,
          longitudeMin: -180,
          organizationId,
          description,
          firmwareVersion,
          groupName,
          serialNumber,
        });

        this.controllersOnMap = results.map((controller) =>
          patchController(controller),
        ) as ControllerWithCoordinates[];
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getController(serialNumber: string) {
      try {
        this.controller = patchController(await getController(serialNumber));
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getMqtt(serialNumber: string) {
      try {
        return await getMqtt(serialNumber);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async updateController({
      serialNumber,
      data,
    }: {
      serialNumber: string;
      data: ControllerEditable;
    }) {
      try {
        this.controller = patchController(
          await updateController({ serialNumber, data }),
        );
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async createTunnel({
      serialNumber,
      protocol,
    }: {
      serialNumber: string;
      protocol: TunnelProtocol;
    }) {
      try {
        return await createTunnel({ serialNumber, protocol });
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async activateController({
      token,
      organizationId,
    }: {
      token: string;
      organizationId: string;
    }) {
      try {
        return await activateController({ token, organizationId });
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async validateController(token: string) {
      try {
        return await validateController(token);
      } catch (error: any) {
        return null;
      }
    },
    async deleteController(serialNumber: string) {
      try {
        await deleteController(serialNumber);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getDiagnostic(serialNumber: string) {
      try {
        return await getDiagnostic(serialNumber);
      } catch (error: any) {
        return {
          status: null,
          id: null,
          created: null,
        };
      }
    },
    async requestDiagnostic(serialNumber: string) {
      try {
        return await requestDiagnostic(serialNumber);
      } catch (error: any) {
        return null;
      }
    },
  },
  getters: {
    getControllerBySerialNumber() {
      return (serialNumber: string) =>
        this.controllers
          ? this.controllers.find(
              (controller) => controller.serialNumber === serialNumber,
            )
          : undefined;
    },
  },
});
