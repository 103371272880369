// PT stands for PassThrough
// https://primevue.org/unstyled/

import type { ButtonProps } from 'primevue/button';
import type { ChipProps } from 'primevue/chip';
import type { MessageProps } from 'primevue/message';
import type { SelectProps } from 'primevue/select';

const passwordIconClass =
  'absolute text-gray-hover hover:text-black transition-colors right-0 h-full w-32 p-8 top-0';

export default {
  button: {
    root: ({ props }: { props: ButtonProps }) => ({
      class: [
        'disabled:opacity-50 disabled:border-[#e2e8f0] whitespace-nowrap rounded border py-4 transition-colors cursor-pointer flex gap-8 items-center',
        {
          'button-primary button': props.severity === null,
          'button disabled:bg-[#e2e8f0]': props.severity === 'secondary',
          'button text-red': props.severity === 'danger',
        },
      ],
    }),
    label: () => ({
      class: ['font-normal'],
    }),
    loadingicon: ['animate-spin'],
  },
  inputtext: {
    root: () => ({ class: ['input', 'w-full'] }),
  },
  textarea: {
    root: () => ({ class: ['input', 'w-full'] }),
  },
  inputmask: {
    root: () => ({ class: ['input', 'w-full', 'no-ligatures'] }),
  },
  multiselect: {
    root: () => ({ class: ['input', 'flex', 'justify-between'] }),
    panel: () => ({
      class: ['rounded', 'shadow', 'bg-white', 'input', 'p-8', 'mt-8'],
    }),
    filterContainer: () => ({ class: ['input', 'flex', 'items-center'] }),
    filterInput: () => ({ class: ['flex-grow'] }),
  },
  password: {
    root: () => ({ class: ['relative'] }),
    input: () => ({ class: ['input', 'w-full', 'pr-32'] }),
    unmaskicon: () => ({
      class: [passwordIconClass],
    }),
    maskicon: () => ({
      class: [passwordIconClass],
    }),
    info: ['mb-8', 'font-bold'],
    meter: ['bg-gray', 'h-8', 'rounded', 'mb-8', 'mt-12', 'overflow-hidden'],
    meterLabel: [
      'bg-[#5cb300]',
      'h-[100%]',
      'w-0',
      'transition-all',
      'duration-1000',
      'ease-in-out',
    ],
    panel: ['border', 'border-[#e2e8f0]', 'bg-white', 'rounded-b', 'p-8'],
    overlay: ['p-8', 'shadow', 'bg-white', 'rounded', 'border', 'border-gray'],
  },
  chip: {
    root: ({ props }: { props: ChipProps }) => ({
      class: [
        'inline-flex px-8 py-2 items-center gap-8 rounded bg-gray text-black text-opacity-70 font-mono uppercase',
        {
          'hover:bg-gray-hover': props.removable === true,
        },
      ],
    }),
  },
  dialog: {
    root: ['container-fluid max-w-[768px] m-16'],
    mask: ['bg-black bg-opacity-50 !z-[90]'],
  },
  breadcrumb: {
    menu: ['flex items-center gap-8'],
    label: ['link'],
  },
  skeleton: {
    root: ['bg-gray rounded block h-full animate-pulse bg-opacity-50'],
  },
  select: {
    root: ({ props }: { props: SelectProps }) => [
      'flex',
      'rounded',
      'border',
      'border-gray',
      'gap-24',
      'items-center',
      'hover:bg-[#d1d5db80]',
      'cursor-pointer',
      'whitespace-nowrap',
      {
        'py-8 px-16': !props.size || props.size === 'large',
        'py-[4px] px-[12px]': props.size === 'small',
      },
    ],
    listContainer: ['overflow-y-auto'],
    list: ['p-[3px]', 'gap-[2px]', 'flex', 'flex-col'],
    overlay: ['bg-white', 'rounded', 'border', 'border-gray'],
    option: [
      'px-[12px]',
      'py-[4px]',
      'cursor-pointer',
      'hover:bg-[#f1f5f9]',
      'rounded',
    ],
  },
  menu: {
    root: ['border', 'border-[#e2e8f0]', 'bg-white', 'rounded-b'],
    itemcontent: [
      'px-16',
      'py-8',
      'cursor-pointer',
      'hover:bg-gray',
      'hover:bg-opacity-25',
    ],
    label: ['flex', 'w-100%', 'whitespace-nowrap'],
    separator: ['border-t-[1px]', 'border-[#e2e8f0]', 'm-2'],
  },
  pcmenu: {
    rootlist: ['bg-white', 'rounded-b', 'border', 'border-[#e2e8f0]'],
    itemcontent: ['bg-white', 'bg-red', 'w-full'],
    itemlink: [
      'flex',
      'px-16',
      'py-8',
      'cursor-pointer',
      'hover:bg-gray',
      'hover:bg-opacity-25',
    ],
  },
  message: {
    root: ({ props }: { props: MessageProps }) => ({
      class: [
        'p-8 border rounded  text-black text-opacity-80',
        {
          'bg-yellow bg-opacity-20 border-yellow-hover':
            props.severity === 'warn',
          'bg-red bg-opacity-20 border-red-hover': props.severity === 'error',
          'bg-[#96daff] bg-opacity-20 border-opacity-20 border-[#96daff]':
            props.severity === 'info',
          'bg-green bg-opacity-20 border-green-hover':
            props.severity === 'success',
        },
      ],
    }),
    content: ['flex gap-4'],
    text: ['flex-grow'],
  },
  timeline: {
    event: ['flex', 'min-h-[70px]', 'last:min-h-0'],
    eventseparator: ['flex', 'flex-col', 'items-center'],
    eventconnector: ['bg-[#e2e8f0]', 'w-[2px]', 'grow', 'ml-[-18px]', '-z-10'],
    eventcontent: ['pl-16', 'leading-[20px]'],
    eventopposite: [
      'bg-gray',
      'rounded-2xl',
      'w-3',
      'flex-grow-1',
      'border',
      'border-2',
      'border-[#e2e8f0]',
      'w-[1.125rem]',
      'h-[1.125rem]',
      'flex',
      'justify-center',
      'items-center',
      'bg-white',
      'before:block',
      'before:rounded-2xl',
      'before:bg-[#5cb300]',
      'before:w-[5px]',
      'before:h-[5px]',
      'before:content-["⠀"]',
    ],
  },
};
