import { TOKEN_URL } from '@/api/auth';
import { displayError, parseError } from '../displayError';
import {
  GET_CONTROLLER_REGEX,
  GET_MQTT_REGEX,
  VALIDATE_CONTROLLER_REGEX,
} from '@/api/controllers';
import { CREATE_USER_URL } from '@/api/users';

export const showErrorToUser = (error: any) => {
  const statusCode = error.response?.status;
  const url = error.config.url;

  if (url.match(VALIDATE_CONTROLLER_REGEX)) {
    // Don't show validate error as it shown in interface, see VAddControllerFailModal
  } else if (statusCode === 401) {
    // Mute all 401 error, except for login request (when password is incorrect for example)
  } else if (statusCode === 404) {
    // User friendly 404
    const getControllerMatch = url.match(GET_CONTROLLER_REGEX);
    if (getControllerMatch) {
      displayError([
        `Контроллер ${getControllerMatch.groups.serialNumber} не существует`,
      ]);
    } else if ([GET_MQTT_REGEX].some((regex) => url.match(regex))) {
      // Do nothing
    }
  } else if (
    [400, 401].includes(statusCode) &&
    [CREATE_USER_URL, TOKEN_URL].some((v) => url.includes(v))
  ) {
    // #FIXME all 400 errors are in toasts, but must be in forms. This need to be refactored to remove all 400s from toasts
  } else {
    if (['/api/v1/controllers-count/', '/api/v1/groups/'].includes(url)) {
      return;
    }

    displayError(parseError(error));
  }
};
