import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createI18n } from 'vue-i18n';
import { getLocale } from '@/utils/locale';
import { slavicPluralization } from '@/utils/slavicPluralization';
import App from './App.vue';
import pt from './pt';
import router from './router';

import '@/style.scss';

const app = createApp(App);

export const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  pluralRules: {
    ru: slavicPluralization,
  },
});

const pinia = createPinia();

app.use(i18n);
app.use(pinia);
app.use(router);
app.use(ToastService);
app.directive('tooltip', Tooltip);
app.use(PrimeVue, { unstyled: true, pt });

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    enabled: ['production', 'staging'].includes(process.env.NODE_ENV),
    environment: process.env.NODE_ENV,
    dsn: import.meta.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend(event, hint) {
      // decided not to send certain network errors
      if (
        hint?.originalException &&
        axios.isAxiosError(hint.originalException)
      ) {
        const ignoredStatuses = [401, 404];
        if (
          ignoredStatuses.includes(
            hint.originalException.response?.status as number,
          )
        ) {
          return null;
        }
      }
      return event;
    },
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });
}

app.mount('#app');

export default app;
