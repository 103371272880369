import request from '@/utils/request';
import type {
  BackendController,
  Pagination,
  Mqtt,
  TimeseriesPoint,
  TimeseriesRequestPayload,
  ControllerEditable,
  GetControllersParams,
  TunnelProtocol,
  Tunnel,
  Controller,
  Count,
  Diagnostic,
} from '@/types';

export const getControllers = async ({
  page,
  ordering,
  groupIds,
  pageSize = 0,
  latitudeMax,
  latitudeMin,
  longitudeMax,
  longitudeMin,
  organizationId,
  description,
  firmwareVersion,
  groupName,
  serialNumber,
}: GetControllersParams) => {
  const url = `/api/v1/controllers/`;

  return (
    await request.get(url, {
      params: {
        page,
        ordering,
        group_ids: groupIds,
        page_size: pageSize,
        latitude_max: latitudeMax,
        latitude_min: latitudeMin,
        longitude_max: longitudeMax,
        longitude_min: longitudeMin,
        organization_id: organizationId,
        firmware_version: firmwareVersion,
        serial_number: serialNumber,
        group_name: groupName,
        description,
      },
    })
  ).data as Pagination<BackendController>;
};

export const getControllersCount = async ({
  page,
  groupIds,
  pageSize = 0,
  latitudeMax,
  latitudeMin,
  longitudeMax,
  longitudeMin,
  organizationId,
  description,
  firmwareVersion,
  groupName,
  serialNumber,
}: GetControllersParams) => {
  const url = `/api/v1/controllers-count/`;

  return (
    await request.get(url, {
      params: {
        page,
        group_ids: groupIds,
        page_size: pageSize,
        latitude_max: latitudeMax,
        latitude_min: latitudeMin,
        longitude_max: longitudeMax,
        longitude_min: longitudeMin,
        organization_id: organizationId,
        firmware_version: firmwareVersion,
        serial_number: serialNumber,
        group_name: groupName,
        description,
      },
    })
  ).data as Count;
};

export const GET_CONTROLLER_REGEX =
  /\/api\/v1\/controllers\/(?<serialNumber>[A-z0-9]*)\/$/;

export const getController = async (
  serialNumber: string,
): Promise<BackendController> => {
  const url = `/api/v1/controllers/${serialNumber}/`;

  return (await request.get(url)).data as BackendController;
};

export const updateController = async ({
  serialNumber,
  data,
}: {
  serialNumber: string;
  data: ControllerEditable;
}) => {
  const url = `/api/v1/controllers/${serialNumber}/`;

  return (await request.patch(url, { ...data })).data as BackendController;
};

export const GET_MQTT_REGEX =
  /\/api\/v1\/controllers\/(?<serialNumber>[A-z0-9]*)\/last-mqtt-data\/$/;

export const getMqtt = async (serialNumber: string): Promise<Mqtt[]> => {
  const url = `/api/v1/controllers/${serialNumber}/last-mqtt-data/`;

  return (await request.get(url)).data as Mqtt[];
};

export const getGraphData = async (
  serialNumber: string,
  data: TimeseriesRequestPayload,
): Promise<TimeseriesPoint[]> => {
  const url = `/api/v1/controllers/${serialNumber}/system-metrics/`;

  return (await request.post(url, { ...data })).data as TimeseriesPoint[];
};

export const getDiagnostic = async (
  serialNumber: string,
): Promise<Diagnostic> => {
  const url = `/api/v1/controllers/${serialNumber}/diagnostic/`;

  return (await request.get(url)).data;
};

export const requestDiagnostic = async (
  serialNumber: string,
): Promise<string> => {
  const url = `/api/v1/controllers/${serialNumber}/request-diagnostic/`;

  return (await request.post(url)).data;
};

export const downloadDiagnostic = async (dowloadId: string): Promise<File> => {
  const url = `/api/v1/download/${dowloadId}`;

  return (
    await request.get(url, {
      responseType: 'blob',
    })
  ).data;
};

export const createTunnel = async ({
  serialNumber,
  protocol,
}: {
  serialNumber: string;
  protocol: TunnelProtocol;
}) => {
  const url = `/api/v1/controllers/${serialNumber}/tcp-tunnels/${protocol}/`;

  return (await request.post(url)).data as Tunnel;
};

export const activateController = async ({
  token,
  organizationId,
}: {
  token: string;
  organizationId: string;
}) => {
  const url = `/api/v1/controllers-activation/activate/`;

  return (await request.post(url, { token, organization: organizationId }))
    .data as Controller;
};

const VALIDATE_CONTROLLER_URL = '/api/v1/controllers-activation/validate/';
export const VALIDATE_CONTROLLER_REGEX =
  /\/api\/v1\/controllers-activation\/validate\/$/;

export const validateController = async (token: string) => {
  const url = VALIDATE_CONTROLLER_URL;

  return (await request.post(url, { token })).data as {
    controllerSerialNumber: string;
    organizationToBind: string;
  };
};

export const deleteController = async (serialNumber: string) => {
  const url = `/api/v1/controllers/${serialNumber}/`;

  await request.delete(url);
};
