import request from '@/utils/request';
import type { UserEditableData, User } from '@/types';

export const GET_USER_URL = '/api/v1/users/me/';

export const getUser = async (): Promise<any> => {
  return (await request.get(GET_USER_URL)).data as User;
};

export const CREATE_USER_URL = '/api/v1/users-sign-up/';

export const createUser = async ({
  userEmail,
  userPassword,
  organizationInviteToken,
}: UserEditableData): Promise<any> => {
  return await request.post(CREATE_USER_URL, {
    userEmail: userEmail.toLowerCase(),
    userPassword,
    organizationInviteToken,
  });
};
