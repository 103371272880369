import type { Group } from '@/types';

export const flattenGroup = (rootGroup: Group): Group[] => {
  const flatGroups: Group[] = [];

  const flatten = (group: Group) => {
    flatGroups.push(group);

    if (group.subgroups) {
      group.subgroups.forEach((child) => {
        flatten(child);
      });
    }
  };

  flatten(rootGroup);

  return flatGroups;
};

export const flattenGroups = (groups: Group[]) => {
  let result: Group[] = [];

  groups.forEach((group) => {
    result = [...result, ...flattenGroup(group)];
  });

  return result;
};
