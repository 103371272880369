import { defineStore } from 'pinia';
import type {
  FetchedItems,
  FetchedItem,
  Organization,
  OrganizationMember,
  Role,
} from '@/types';
import {
  sendInviteToOrganization,
  validateInvite,
  enrollToOrganization,
  getOrganizations,
  getOrganization,
  leaveOrganization,
  getMembers,
  removeMember,
  changeRole,
  getGrafanaUrl,
  addOrganization,
  updateOrganization,
} from '@/api/organizations';

export const useOrganizationsStore = defineStore('organizationsStore', {
  state: (): {
    organizations: FetchedItems<Organization>;
    organization: FetchedItem<Organization>;
    members: FetchedItems<OrganizationMember>;
  } => ({
    organizations: undefined,
    organization: undefined,
    members: undefined,
  }),
  actions: {
    async sendInviteToOrganization(
      organizationId: string,
      email: string,
    ): Promise<{ id: string }> {
      try {
        return await sendInviteToOrganization(organizationId, email);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async validateInvite(token: string) {
      try {
        return await validateInvite(token);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async enrollToOrganization(token: string) {
      try {
        return await enrollToOrganization(token);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getOrganizations() {
      try {
        this.organizations = await getOrganizations();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async addOrganization(name: string) {
      try {
        return await addOrganization(name);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getOrganization(organizationId: string) {
      try {
        this.organization = await getOrganization(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async updateOrganization({
      organizationId,
      name,
    }: {
      organizationId: string;
      name: string;
    }) {
      try {
        await updateOrganization({ organizationId, name });
        await this.getOrganization(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async leaveOrganization(organizationId: string) {
      try {
        await leaveOrganization(organizationId);
        await this.getOrganizations();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getMembers(organizationId: string) {
      try {
        this.members = await getMembers(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async removeMember({
      organizationId,
      userId,
    }: {
      organizationId: string;
      userId: string;
    }) {
      try {
        await removeMember({ organizationId, userId });
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async changeRole({
      organizationId,
      userId,
      role,
    }: {
      organizationId: string;
      userId: string;
      role: Role;
    }) {
      try {
        await changeRole({ organizationId, userId, role });
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async getGrafanaUrl(organizationId: string) {
      try {
        return await getGrafanaUrl(organizationId);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  },
});
