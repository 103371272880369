import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { forbidToAuthorized } from './forbidToAuthorized';
import { deauthorize } from './deauthorize';
import { useBannerStore } from '@/stores/banner';
import { redirectFromRoot } from './redirectFromRoot';
import { invite } from './invite';
import { useOrganizationsStore } from '@/stores/organizations';
import { getController } from '@/api/controllers';
import { useGroupsStore } from '@/stores/groups';
import { useControllersStore } from '@/stores/controllers';

const VAddControllerView = () =>
  import('@/views/VAddControllerView/VAddControllerView.vue');
const VControllerView = () =>
  import('@/views/VControllerView/VControllerView.vue');
const VControllersView = () =>
  import('@/views/VControllersView/VControllersView.vue');
const VLoginView = () => import('@/views/VLoginView/VLoginView.vue');
const VSignUpView = () => import('@/views/VSignUpView/VSignUpView.vue');
const VConnectView = () => import('@/views/VConnectView/VConnectView.vue');
const VResetPasswordView = () =>
  import('@/views/VResetPasswordView/VResetPasswordView.vue');
const VChangePasswordView = () =>
  import('@/views/VChangePasswordView/VChangePasswordView.vue');
const VResetPasswordRequestView = () =>
  import('@/views/VResetPasswordRequestView/VResetPasswordRequestView.vue');
const VOrganizationsView = () =>
  import('@/views/VOrganizationsView/VOrganizationsView.vue');
const VOrganizationView = () =>
  import('@/views/VOrganizationView/VOrganizationView.vue');
const VLoadingView = () => import('@/views/VLoadingView/VLoadingView.vue');

export const getLoginWithRedirect = (to: RouteLocationNormalized) => ({
  name: 'login',
  query: { redirect: encodeURIComponent(to.fullPath) },
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      component: VLoadingView,
      beforeEnter: [redirectFromRoot],
    },
    {
      path: '/controllers',
      name: 'add-controller',
      component: VAddControllerView,
      meta: { requiresAuth: true },
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: VOrganizationsView,
      meta: { requiresAuth: true },
    },
    {
      path: '/organizations/:organizationId',
      name: 'organization',
      component: VOrganizationView,
      meta: { requiresAuth: true },
    },
    {
      path: '/organizations/:organizationId/controllers',
      name: 'controllers',
      component: VControllersView,
      meta: { requiresAuth: true },
    },
    {
      // This route exists to support links that were created before we added multi-organization support
      path: '/controllers/:serialNumber',
      component: VLoadingView,
      meta: { requiresAuth: true },
      beforeEnter: [
        async (to) => {
          const controller = await getController(
            String(to.params.serialNumber),
          );

          return {
            name: 'controller',
            params: {
              ...to.params,
              organizationId: controller.organization.id,
            },
          };
        },
      ],
    },
    {
      path: '/organizations/:organizationId/controllers/:serialNumber',
      name: 'controller',
      component: VControllerView,
      meta: { requiresAuth: true },
    },
    {
      path: '/login',
      name: 'login',
      component: VLoginView,
      meta: { requiresAuth: false },
      beforeEnter: [forbidToAuthorized],
    },
    {
      path: '/connect/:protocol(http|ssh)/:serialNumber/',
      name: 'connect',
      component: VConnectView,
      meta: { requiresAuth: true },
    },
    {
      path: '/signup',
      name: 'signup',
      component: VSignUpView,
      meta: { requiresAuth: false },
      beforeEnter: [deauthorize],
    },
    {
      path: '/invite',
      name: 'invite',
      component: VLoadingView,
      meta: { requiresAuth: false }, // Redirects are handled in invite.ts
      beforeEnter: [invite],
    },
    {
      path: '/password-reset/',
      name: 'password-reset-request',
      component: VResetPasswordRequestView,
    },
    {
      path: '/password-reset/:uid/:token/',
      name: 'password-reset',
      component: VResetPasswordView,
    },
    {
      path: '/password-change',
      name: 'password-change',
      component: VChangePasswordView,
      meta: { requiresAuth: true },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
});

// @ts-ignore
// # FIXME: Fix types
router.beforeEach((to, from) => {
  const authStore = useAuthStore();
  const bannerStore = useBannerStore();
  const organizationsStore = useOrganizationsStore();
  const groupsStore = useGroupsStore();
  const controllersStore = useControllersStore();

  bannerStore.$reset();

  // Reset organizations store when switching between organizations
  if (to.params.organizationId !== from.params.organizationId) {
    controllersStore.$reset();
    organizationsStore.$reset();
    groupsStore.$reset();
  }

  // Always uppercase serialNumber because backend needs it for smth
  if (to.params.serialNumber) {
    const uppercaseSerialNumber = String(to.params.serialNumber).toUpperCase();
    if (to.params.serialNumber !== uppercaseSerialNumber) {
      return {
        name: to.name,
        params: {
          ...to.params,
          serialNumber: uppercaseSerialNumber,
        },
        hash: to.hash,
        query: { ...to.query },
        replace: true,
      };
    }
  }

  if (to.meta.requiresAuth && !authStore.isAuthorized.value) {
    // See @/composables/useRedirectUrl
    return getLoginWithRedirect(to);
  }
});

export default router;
