import request from '@/utils/request';
import type { LoginResponse } from '@/types';
import mem from 'mem';

export const TOKEN_URL = '/api/v1/auth/token/';

export const getToken = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<LoginResponse> => {
  const url = TOKEN_URL;
  const data = {
    username: username.toLowerCase(),
    password,
  };

  return (await request.post(url, data)).data as LoginResponse;
};

export const REFRESH_URL = '/api/v1/auth/token/refresh/';

export const refreshToken = async (refresh: string): Promise<LoginResponse> => {
  const data = {
    refresh,
  };

  return (await request.post(REFRESH_URL, data)).data as LoginResponse;
};

export const memRefreshToken = mem(refreshToken);

export const LOGOUT_URL = '/api/v1/auth/logout/';

export const logout = async (refresh: string): Promise<void> => {
  const data = {
    refresh,
  };

  await request.post(LOGOUT_URL, data);
};

export const memLogout = mem(logout);

export const PASSWORD_RESET_URL = '/api/v1/auth/password/reset/';

export const requestPasswordReset = async (email: string): Promise<void> => {
  const data = {
    email,
  };

  await request.post(PASSWORD_RESET_URL, data);
};

export const PASSWORD_RESET_CONFIRM_URL =
  '/api/v1/auth/password/reset/confirm/';

export const confirmPasswordReset = async ({
  uid,
  token,
  newPassword,
}: {
  uid: string;
  token: string;
  newPassword: string;
}): Promise<void> => {
  const data = {
    uid,
    token,
    newPassword,
  };

  await request.post(PASSWORD_RESET_CONFIRM_URL, data);
};

export const PASSWORD_CHANGE_URL = '/api/v1/auth/password/change/';

export const passwordChange = async (
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  await request.put(PASSWORD_CHANGE_URL, { oldPassword, newPassword });
};
