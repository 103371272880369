import { defineStore } from 'pinia';

export const useBannerStore = defineStore('bannerStore', {
  state: (): { message: string | undefined } => ({
    message: undefined,
  }),
  actions: {
    setBanner(message: string | undefined = undefined) {
      this.message = message;
    },
  },
});
