import type {
  InviteData,
  Organization,
  OrganizationMember,
  Role,
} from '@/types';
import request from '@/utils/request';

export const sendInviteToOrganization = async (
  organizationId: string,
  email: string,
) => {
  const url = `/api/v1/organizations/${organizationId}/invite-user/`;

  return (await request.post(url, { email })).data as { id: string };
};

export const validateInvite = async (token: string) => {
  const url = '/api/v1/organizations-invite/validate/';

  return (await request.post(url, { organizationInviteToken: token }))
    .data as InviteData;
};

export const enrollToOrganization = async (token: string) => {
  const url = '/api/v1/organizations-invite/self-enroll/';

  return (await request.post(url, { organizationInviteToken: token }))
    .data as Organization;
};

export const getOrganizations = async () => {
  const url = '/api/v1/organizations/';

  return (await request.get(url)).data as Organization[];
};

export const addOrganization = async (name: string) => {
  const url = '/api/v1/organizations/';

  return (await request.post(url, { name })).data as Organization;
};

export const getOrganization = async (organizationId: string) => {
  const url = `/api/v1/organizations/${organizationId}/`;

  return (await request.get(url)).data as Organization;
};

export const updateOrganization = async ({
  organizationId,
  name,
}: {
  organizationId: string;
  name: string;
}) => {
  const url = `/api/v1/organizations/${organizationId}/`;

  return (await request.put(url, { name })).data as Organization;
};

export const leaveOrganization = async (organizationId: string) => {
  const url = `/api/v1/organizations/${organizationId}/leave/`;

  await request.post(url);
};

export const getMembers = async (organizationId: string) => {
  const url = `/api/v1/organizations/${organizationId}/members/`;

  return (await request.get(url)).data as OrganizationMember[];
};

export const removeMember = async ({
  organizationId,
  userId,
}: {
  organizationId: string;
  userId: string;
}) => {
  const url = `/api/v1/organizations/${organizationId}/members/${userId}/`;

  await request.delete(url);
};

export const changeRole = async ({
  organizationId,
  userId,
  role,
}: {
  organizationId: string;
  userId: string;
  role: Role;
}) => {
  const url = `/api/v1/organizations/${organizationId}/members/${userId}/`;

  await request.put(url, { role });
};

export const getGrafanaUrl = async (organizationId: string) => {
  const url = `/api/v1/organizations/${organizationId}/grafana-access/`;

  return (await request.get(url)).data.grafanaUrl as string | null;
};
