export const getLocale = () => {
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale) {
    return savedLocale;
  }
  const browserLocale = navigator.language || navigator.languages[0];
  return browserLocale ? browserLocale.split('-')[0] : 'en';
};

export const setLocale = (locale: string) => {
  localStorage.setItem('locale', locale);
};
