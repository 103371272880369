import { defineStore } from 'pinia';
import { getUser, createUser } from '@/api/users';
import type { UserEditableData, User, FetchedItem } from '@/types';

export const useUserStore = defineStore('userStore', {
  state: (): { user: FetchedItem<User> } => ({
    user: undefined,
  }),
  actions: {
    async getUser() {
      try {
        this.user = await getUser();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    async createUser({
      userEmail,
      userPassword,
      organizationInviteToken,
    }: UserEditableData) {
      try {
        await createUser({
          userEmail,
          userPassword,
          organizationInviteToken,
        });
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  },
});
